@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  margin: 0;
  
  font-family: 'Poppins';
  font-style: 'normal';  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  
  font-family: 'Poppins';
  font-style: normal;
}

.Select {
  width: 100%;
  height: 40px;
}

.InputLabel {
    margin-left: 10px;
    font-weight: 500;
    color: black;
    font-size: larger;
}
.Boxsurvey {
  display: flex;
  justify-content: center;
}
.Cardsurvey {
  border-radius: "15px";
  border: "1px solid white";
  box-shadow: 10;
  justify-content: space-between;
  width: 480;
  display: flex;
  height: 120;
}

.Buttonschool{
text-transform: capitalize;
background: "#3d3d73";
border-radius: 20;
width: 130;
height: 40;
color:white;
font-family: "Poppins";
font-style: normal;
margin-top: 30;
}

